import React from "react"
import Layout from "../../components/layout"
import { Container, Wrapper } from "../../theme"
import {
  ServicesRow,
  Process,
  ServiceExamples,
  ContentGrid,
  IconRow,
  TwoColumns,
} from "../../components"
import { Colors } from "../../theme/styles"

const DigitalMarketing = () => {
  return (
    <Layout title="Digital Marketing Services" type="marketing">
      <Wrapper white>
        <Container>
          <IconRow type="marketing" />
        </Container>
        <ServicesRow
          serviceTitle="Generating Leads Through Digital Marketing"
          subtitle="Inbound marketing that brings new business to you"
          category="Our Marketing Services"
          service="marketing"
          paragraph1="Marketing is the key that unlocks everything in your business. With great marketing, your company can enjoy the benefits of having leads come to you. That's our goal here at CheshTech is to help bring new business to you without the pains of having to do any cold outreach. Nobody likes cold calling or getting cold called!"
          paragraph2="We work specifically with you to learn about the goals of your business and how your marketing supports those goals. We will create a customized strategy for your business based on those goals in order to get maximized results."
          paragraph3="We are proud to offer a variety of funnel driven services to drive traffic to your website, or complete your specific call to action. We focus our digital marketing services on social media management, content creation, branding, email marketing, On-page and technical SEO, and content marketing strategies including blogging and vlogging."
        />
        <ContentGrid type="marketing" />
        <TwoColumns
          background={Colors.lightBlue}
          color={Colors.white}
          type="marketing"
          title="Meet our Director of Marketing"
          paragraph1="Raquel has been with CheshTech since January of 2021! She began as
       the social media marketing manager and is now in charge of
       marketing for our client projects. She's Latina and proud having
       been born in Peru before moving to France while she was young.
       Raquel speaks an impressive three languages fluently (Spanish,
       French, and English)."
          paragraph2="She specializes in social media marketing, branding, and content creation. Her emphasis is on engagement and organic growth to build, engage, and keep a consistent and loyal brand of followers to your brand. In addition, she is an expert in overall marketing strategy to properly build marketing funnels that create organic traffic to your website to fulfill your company's goals. Interested in working with CheshTech and Raquel? Book a free consultation below!"
        />
        <Process type="marketing" />
        <ServiceExamples service="marketing" title="Our marketing clients" />
      </Wrapper>
    </Layout>
  )
}

export default DigitalMarketing
